import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'

export default () => (
  <Layout>
    <div className="mx-auto leading-normal">
      <p className="leading-normal">Thank you, your message has been sent. We'll get back to you ASAP!</p>
      <br />
      <Link to="/" className="no-underline text-viewsource border-b-2 border-viewsource border-solid">
        Back to Frontpage
      </Link>
    </div>
  </Layout>
)
