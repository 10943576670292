import React from 'react'
import PropTypes from 'prop-types'

import Header from './Header'
import Footer from './Footer'
import '../css/style.css'

const Layout = ({ children }) => (
  <div className="flex flex-col font-sans min-h-screen text-grey-darkest">
    <Header />

    <section className="flex-1 pb-4">
      <div className="flex justify-between max-w-xl mx-auto p-6 md:p-10">
        <div className="max-w-md">
          {children}
        </div>
        {/* sorry terrible hack. it's not stupid if it works 🤪  */}
        <div className="hidden">{''}</div>
      </div>
    </section>


    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
