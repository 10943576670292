import { Link } from 'gatsby'
import React from 'react'

import ContactButton from './ContactButton.js'

const Header = () => (
  <nav className="bg-transparent">
    <div className="flex flex-wrap items-center justify-between max-w-xl mx-auto p-6 md:p-10">
      <Link to="/" className="flex items-center no-underline">
        <span className="font-light text-3xl mr-15 text-black">Hi, we are</span>
        <span className="font-medium text-3xl text-viewsource border-b-4 border-viewsource border-solid" style={{marginBottom: '-4px'}}>Viewsource</span>
      </Link>
      <ContactButton className="hidden xs:block"/>
    </div>
  </nav>
)

export default Header
