import { Link } from 'gatsby'
import React from 'react'

export default ({ style, className }) => (
  <Link to="/contact" activeStyle={{ display: 'none' }} className={className} style={style}>
    <button className="bg-nephritis hover:bg-viewsource text-white font-light py-3 px-6 rounded">
      Get in Touch
    </button>
  </Link>
)
