import React from 'react'
import { Link } from 'gatsby'

const Footer = () => (
  <footer className="bg-viewsource">
    <div className="flex justify-between max-w-xl mx-auto p-4 md:p-6 text-sm">
      <p className="text-white">&copy; 2019 Viewsource Limited</p>
      <div className="flex flex-row">
        <Link to="/about" className="no-underline mr-1">
          <p className="text-white border-b border-white border-solid">About</p>
        </Link>
        <Link to="/clients" className="no-underline ml-2">
          <p className="text-white border-b border-white border-solid">Clients</p>
        </Link>
      </div>
    </div>
  </footer>
)

export default Footer
